import { openDB } from 'idb';

const DB_NAME = 'GlobalStateDB';
const STORE_NAME = 'Cookies';

export const initDB = async () => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'name' });
      }
    },
  });
};

export const setCookieInDB = async (name, value, minutesToExpire) => {
  const db = await initDB();
  const currentTime = Date.now(); // Current time in milliseconds
  const expires = currentTime + minutesToExpire * 60 * 1000;
  await db.put(STORE_NAME, { name, value, expires });
};

export const getCookieFromDB = async (name) => {
  const db = await initDB();
  return await db.get(STORE_NAME, name);
};

export const deleteCookieFromDB = async (name) => {
  const db = await initDB();
  await db.delete(STORE_NAME, name);
};

export const getAllCookiesFromDB = async () => {
  const db = await initDB();
  return await db.getAll(STORE_NAME);
};

import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div style={{ textAlign: "center", margin: "20px 0" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default LoadingSpinner;

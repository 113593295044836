import { configureStore } from "@reduxjs/toolkit";
import fabricReducer from "../Reducer/fabricReducer";
import toastSlice from "../Reducer/toastSlice";
import mainProductReducer from "../Reducer/mainProductReducer";
import productCategoryReducer from "../Reducer/productCategoryReducer";
import productReducer from "../Reducer/productReducer";
import userReducer from "../Reducer/userReducer";
import filterReducer from "../Reducer/filterReducer";

const store = configureStore({
    reducer: {                
       toast: toastSlice,
       fabric:fabricReducer,
       mainproduct:mainProductReducer,
       product_category:productCategoryReducer,
       product:productReducer,
       user:userReducer,
       filterProduct:filterReducer,
    },
    
});

export default store;
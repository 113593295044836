import React, { useState } from 'react';
import { FaPhone, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa'; // Import required icons
import './AdminLogin.css';
import { Formik, Form, ErrorMessage } from 'formik';
import useLoginConfig from '../../../API/Hooks/useLoginConfig';
import { useMutation } from '@tanstack/react-query';
import { userSignin } from '../../../API/Service/userLoginService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserLoading } from '../../../API/Reducer/userReducer';
import { Spinner } from 'react-bootstrap';
import useToast from '../../../API/GlobalHooks/useToast';
import { handleUserSetLocalStorage, setCookie } from '../../../MIS/Global';
import { setCookieInDB } from '../../../MIS/indexedDBUtils';


const AdminLogin = () => {
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const showToast = useToast();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { validationSchema, initialValues } = useLoginConfig();

  const {userLoading} = useSelector((state) => state.user);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const loginMutation = useMutation({
    mutationFn: userSignin,
    onMutate: () => {      
      dispatch(setUserLoading())
    },
    onSuccess: async (resData) => {                 
      if(resData?.code==200 && Object.keys(resData?.data).length>0){                        
        handleUserSetLocalStorage(resData?.data?.[0],"udata");         
        await setCookieInDB('jcToken',resData?.token, 120);
        navigate("/admin/product")        
      }else if(resData?.code==200 && resData?.data==-1){        
        showToast("Invalid credentials! Please try again", 'danger');        
      }
      else{        
        showToast("something went wrong; Please try again!", 'danger');       
      }        
    },
    onError: (error) => {      
      showToast("Some thing went wrong; \nPlease contact your administrator!", 'danger');     
    },
    onSettled: () => {
      dispatch(setUserLoading())
    },
  });

  const handleSubmit = (values, { resetForm }) => { 
    delete values['id']
    loginMutation.mutate(values, {
      onSuccess: () => {
        resetForm();        
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="off">
          <div className="login-page d-flex justify-content-center align-items-center vh-100">           
            <div className="lcard shadow-lg p-4">
              <div className="text-center mb-4">
                <h2 className="fw-bold text-primary">Welcome Back</h2>
                <p className="text-muted">Sign in to your account</p>
              </div>
              {/* Mobile Number Input */}
              <div className="mb-2 position-relative">
                <label htmlFor="mobileNumber" className="form-label">
                  Mobile Number
                </label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaPhone />
                  </span>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.mob && touched.mob ? 'is-invalid' : ''
                    }`}
                    id="mob"
                    placeholder="Enter your mobile number"
                    {...getFieldProps('mob')}
                    maxLength="10"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Ensures only numeric input
                    }}
                  />
                  <ErrorMessage name="mob" component="div" className="invalid-feedback" />
                </div>
              </div>

              {/* Password Input */}
              <div className="mb-3 position-relative">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaLock />
                  </span>
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    className={`form-control ${
                      errors.upaswd && touched.upaswd ? 'is-invalid' : ''
                    }`}
                    id="upaswd"
                    placeholder="Enter your password"
                    {...getFieldProps('upaswd')}
                  />
                  <ErrorMessage name="upaswd" component="div" className="invalid-feedback" />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>

              {/* Remember Me and Forgot Password */}
              <div className="mb-3 d-flex justify-content-between">
                <div>
                  <input type="checkbox" id="rememberMe" />
                  <label htmlFor="rememberMe" className="ms-2">
                    Remember me
                  </label>
                </div>
                <a href="#!" className="text-primary text-decoration-none">
                  Forgot Password?
                </a>
              </div>

              {/* Login Button */}
              <button type="submit" 
               className="btn lbtn-primary w-100 gap-2 d-flex justify-content-center align-items-center">
                 { userLoading?
                  (<Spinner></Spinner>):""
                  } Login
              </button>

              <div className="text-center mt-3">
                <p className="text-muted">
                  Don't have an account?
                  <a href="#!" className="text-primary text-decoration-none">
                    Sign Up
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AdminLogin;

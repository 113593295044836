import React, { useState, useEffect, useRef } from "react";
import { Container, Button, Modal } from "react-bootstrap";
import { setSubDescription } from "../../../API/Reducer/mainProductReducer";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid"; // Import uuid for unique IDs
import { BsStars } from "react-icons/bs";

const DescpTable = () => {
  const { subDescription } = useSelector((state) => state.mainproduct);
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null); // Track which row is being edited
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalInput, setModalInput] = useState(""); // State for modal input
  // Initialize local state with Redux state
  useEffect(() => {
    if (subDescription && subDescription.length > 0) {
      setRows(subDescription);
    } else {
      setRows([{ id: Date.now(), description: "", isEditing: false }]);
    }
  }, [subDescription]);

  // Handle input change for a specific row
  const handleInputChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, description: value } : row
      )
    );
  };

  // Enter edit mode
  const handleEnterEditMode = (id) => {
    setEditingRowId(id); // Set the currently editing row
  };

  // Exit edit mode
  const handleExitEditMode = (id) => {
    if (editingRowId === id) {
      setEditingRowId(null); // Clear editing state
      dispatch(setSubDescription(rows)); // Save changes to Redux
    }
  };

  // Add a new row
  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { id: Date.now(), description: "", isEditing: false },
    ]);
  };

  // Delete a row (only if more than one row is present)
  const handleDeleteRow = (id) => {
    if (rows.length > 1) {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      dispatch(setSubDescription(updatedRows));
    }
  };

  // Handle modal input change
  const handleModalInputChange = (e) => {
    setModalInput(e.target.value);
  };

  const handleSubmitModal = () => {
    // Clear all previous rows before adding new ones
    setRows([]);

    // Split the multi-line description and create new rows with unique IDs
    const newRows = modalInput.split("\n").map((line) => ({
      id: uuidv4(), // Generate a unique ID for each new row
      description: line.trim(),
      isEditing: false,
    }));

    // Update the rows state with the new rows
    setRows(newRows);

    // Dispatch the new rows to Redux
    dispatch(setSubDescription(newRows)); // Save to Redux

    // Close the modal and reset the input
    setShowModal(false);
    setModalInput("");
  };

  return (
    <Container>
      <div className="table-responsive pt-2">
        <div>
          <table
            className="table table-sm w-100"
            style={{
              fontSize: "13.5px",
              tableLayout: "fixed",
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "80%" }}>Description</th>
                <th style={{ width: "20%", textAlign: "center",display:"flex",justifyContent: "space-around",gap:"5px" }}>
                  <span>Actions</span>
                  <Button
                    size="sm"
                    onClick={() => setShowModal(true)} // Show modal on button click
                    style={{
                      padding: "0.25rem", // Adjust padding for a larger clickable area
                      fontSize: "12px", // Slightly larger text for better readability
                      background: "#36454F",
                      color: "#fff", // Text color
                      border: "none", // Remove default border
                      borderRadius: "5px", // Rounded corners for a modern look
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
                      cursor: "pointer", // Cursor change on hover
                      transition: "all 0.3s ease", // Smooth transition for hover effects
                      display: "flex", // Use flexbox to align items side by side
                      alignItems: "center", // Vertically center the content
                      justifyContent: "center",

                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.transform = "scale(1.1)")
                    } // Scale effect on hover
                    onMouseLeave={(e) =>
                      (e.target.style.transform = "scale(1)")
                    }
                  >
                    <BsStars/> AI
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody className="list">
              {rows.map((row) => (
                <tr key={row.id}>
                  <td style={{ wordWrap: "break-word" }}>
                    {editingRowId === row.id ? (
                      <textarea
                        value={row.description}
                        onChange={(e) =>
                          handleInputChange(row.id, e.target.value)
                        }
                        onBlur={() => handleExitEditMode(row.id)} // Exit edit mode on blur
                        className="form-control form-control-sm"
                        style={{
                          width: "100%",
                          minHeight: "50px",
                          resize: "none",
                        }}
                        autoFocus
                      />
                    ) : (
                      <div
                        onClick={() => handleEnterEditMode(row.id)} // Enter edit mode on click
                        style={{
                          cursor: "pointer",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        {row.description || "Click to add description"}
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "5px",
                    }}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      style={{
                        padding: "0.25rem 0.5rem",
                        fontSize: "10px",
                      }}
                      onClick={handleAddRow}
                    >
                      Add
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      style={{
                        padding: "0.25rem 0.5rem",
                        fontSize: "10px",
                      }}
                      onClick={() => handleDeleteRow(row.id)}
                      disabled={rows.length === 1} // Disable delete button if only one row
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for multi-line input */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Paste Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            value={modalInput}
            onChange={handleModalInputChange}
            className="form-control"
            rows="10"
            placeholder="Paste the description here"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitModal}>
            Add Description
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DescpTable;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./FooterOne.module.css";
const FooterOne = () => {
  return (
    <Container fluid className={styles?.footerCon}>
      <Row className="align-items-center p-2 pt-4">
        <Col lg={6} md={6} sm={12} className="search">
          <h4>Join our newsletter to Keep upto date with us!</h4>
        </Col>
        <Col lg={6} md={6} sm={12} className={styles.subscribe_container}>
          <input
            type="email"
            className={styles.email_input}
            placeholder="Enter your email"
          />
          <button className={styles.subscribe_button}>Subscribe</button>
        </Col>
      </Row>
      <Row className="pt-2 pb-4">
        <hr className={styles?.hr}/>
      </Row>
      <Row>
        <Col lg={6} md={6} sm={6}>
          <h4>Teeblanka</h4>
          <p>We growing up business with personal all manager</p>
        </Col>
        <Col
          lg={6}
          md={6}
          sm={6}
          className="justify-content-end d-flex align-items-center"
        >
          
          <div className={styles?.linkCont}>
            <div>
              <p>Company</p>
              <p>Blog</p>
              <p>Career</p>
              <p>News</p>
            </div>
            <div >
              <p>Resources</p>
              <p>Documentation</p>
              <p>Papers</p>
              <p>Press Conference</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="pt-4 pb-4">
        <hr className={styles?.hr}/>
      </Row>
      <Row >
        <Col lg={6} md={6} sm={6} className={styles?.yearExp}>
          <p>© 2024-{new Date().getFullYear()} {"  "}Teeblanka</p>
        </Col>
        <Col
          lg={6}
          md={6}
          sm={6}
          className="justify-content-end d-flex align-items-center"
        >
          <div className={styles?.linkCont}>            
              <p>Terms of Service</p>                        
              <p>Privacy Policy</p>            
              <p>Cookies</p>            
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default FooterOne;

import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./UserLogin.module.css";
import InputNormalEL from "../../GlobalComponent/Elements/InputNormalEL";
import { MdClose } from "react-icons/md";
import { FaLock, FaEyeSlash, FaRegEye, FaMobileAlt } from "react-icons/fa";
import useLoginConfig from "../../../API/Hooks/useLoginConfig";
import ButtonEL from "../../GlobalComponent/Elements/ButtonEL";
import { Formik, Form, ErrorMessage } from "formik";
import useToast from "../../../API/GlobalHooks/useToast";
import { userSignin } from "../../../API/Service/userLoginService";
import { setUserLoading,setUserLogin } from "../../../API/Reducer/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { setCookieInDB } from "../../../MIS/indexedDBUtils";
import { handleUserSetLocalStorage } from "../../../MIS/Global";
import { useMutation } from "@tanstack/react-query";
const UserLoginForm = ({ modalClose }) => {
  const dispatch=useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { validationSchema, initialValues } = useLoginConfig();
  const {userLoading} = useSelector((state) => state.user);
  const showToast = useToast();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const loginMutation = useMutation({
    mutationFn: userSignin,
    onMutate: () => {      
      dispatch(setUserLoading())
    },
    onSuccess: async (resData) => {                 
      if(resData?.code==200 && Object.keys(resData?.data).length>0){                        
        handleUserSetLocalStorage(resData?.data?.[0],"userData");         
        await setCookieInDB('jcUserToken',resData?.token, 120);
        dispatch(setUserLogin());    
        modalClose(false); 
      }else if(resData?.code==200 && resData?.data==-1){        
        showToast("Invalid credentials! Please try again", 'danger');        
      }
      else{        
        showToast("something went wrong; Please try again!", 'danger');       
      }        
    },
    onError: (error) => {      
      showToast("Some thing went wrong; \nPlease contact your administrator!", 'danger');     
    },
    onSettled: () => {
      dispatch(setUserLoading())
    },
  });
  const handleSubmit = (values, { resetForm }) => {
    delete values["id"];
    values={...values,user_role:"user",action:"signin"}    
    loginMutation.mutate(values, {
      onSuccess: () => {
        // resetForm();        
      },
    });
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, getFieldProps,handleChange, handleBlur }) => (
          <Form autoComplete="off">
            <Container>
              <Row>
                <Col
                  xs={12}
                  className={`d-flex justify-content-end align-items-center        
                  `}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      modalClose(false);
                    }}
                  >
                    <MdClose style={{ fontSize: "30px" }} />
                  </span>
                </Col>
                <Col
                  xs={12}
                  className={`d-flex flex-column gap-1 justify-content-center 
                  align-items-center mb-1`}
                >
                  <span className={`${styles?.heading}`}>Login Now</span>
                  <span className={`${styles?.subheading}`}>
                    Step into action—sign in to unlock your journey!
                  </span>
                </Col>
                <Col xs={12} className={`mb-2`}>
                  <InputNormalEL
                    label="Mobile Number"
                    type="number"
                    id="mob"
                    name="mob"
                    hideStepper={true}
                    startIcon={<FaMobileAlt />}
                    startIconBgColor="#0096FF" 
                    maxLength={10}
                    error={touched.mob && errors.mob}
                    touched={touched.mob}
                    {...getFieldProps("mob")}
                    events={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                  />
                </Col>
                <Col xs={12} className={`mb-1`}>
                  <InputNormalEL
                    label="Password"
                    id="upaswd"
                    type={passwordVisible ? "text" : "password"}
                    startIcon={<FaLock />}
                    endIcon={
                      <div onClick={togglePasswordVisibility}>
                        {passwordVisible ? <FaRegEye /> : <FaEyeSlash />}
                      </div>
                    }
                    startIconBgColor="#0096FF"
                    endIconBgColor="#0096FF"
                    maxLength={30}
                    error={errors.upaswd}
                    touched={touched.upaswd}
                    {...getFieldProps('upaswd')}   
                    events={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}                 
                  />
                </Col>
                <Col xs={12} className={`${styles?.cursor} text-end`}>
                  <span className={`text-primary`}>Forgot Password?</span>
                </Col>
                <Col xs={12} className={`mb-2`}>
                  <span className={`${styles?.subheading} text-justify`}>
                    By continuing, you agree to Teeblanka's
                    <span className={`text-info`}>&nbsp;Terms of Use</span> and
                    <span className={`text-info`}>&nbsp;Privacy Policy</span>.
                  </span>
                </Col>
                <Col
                  xs={12}
                  className={`d-flex justify-content-center align-items-center`}
                >
                  <ButtonEL
                    className={styles?.loginButton}
                    type="submit"
                    // variant="info"
                    isLoading={userLoading}
                    loadingText="sign in..."
                  >
                    Login
                  </ButtonEL>
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserLoginForm;

import React from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa"; // Importing React Icons for arrows
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BannerSlider.css"; // Custom styles
import img1 from '../../../Assets/BANNER 1.jpg';
import img2 from '../../../Assets/BANNER 2.jpg';
import img3 from '../../../Assets/BANNER 3.jpg';
import img4 from '../../../Assets/BANNER 4.jpg';
import img5 from '../../../Assets/BANNER 5.jpg';

// Custom Next Arrow
const NextArrow = ({ onClick }) => {
  return (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      <FaArrowRight /> {/* Right Arrow Icon from React Icons */}
    </div>
  );
};

// Custom Prev Arrow
const PrevArrow = ({ onClick }) => {
  return (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      <FaArrowLeft /> {/* Left Arrow Icon from React Icons */}
    </div>
  );
};

const BannerSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const images = [img1, img2, img3, img4, img5];

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index}>
            <img src={img} alt={`Slide ${index + 1}`} className="carousel-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerSlider;

import React, { useState } from 'react';
import styles from './PaginationComponent.module.css';
import { useDispatch } from 'react-redux';
import { setPaginationRowNumber } from '../../../API/Reducer/filterReducer';

const PaginationComponent = ({ totalPages = 3,limits=10,totalRows=29 }) => {
  const dispatch=useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const calculateRowNumbers=(newPage, limit, totalRecords)=>{
    // alert(newPage + " - " +limit+" - "+totalRecords)    
    const firstRow = (newPage - 1) * limit + 1;
    const lastRow = Math.min(newPage * limit, totalRecords);
  
    return { firstRow, lastRow };
  }

  const handleCurrentPage = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      const { firstRow, lastRow } = calculateRowNumbers(pageNumber, limits, totalRows);
      dispatch(setPaginationRowNumber({firstRow:firstRow,lastRow:lastRow}))
      setCurrentPage(pageNumber);
      
    }
  };

  const renderPaginationItems = () => {
    const items = [];
    const siblingCount = 5; // Number of pages to show around the current page
    const ellipsis = <span className={styles.ellipsis}>...</span>;

    if (currentPage > siblingCount + 2) {
      items.push(
        <button
          key="first"
          className={styles.pageButton}
          onClick={() => handleCurrentPage(1)}
        >
          1
        </button>,
        ellipsis
      );
    }

    for (let i = Math.max(1, currentPage - siblingCount); i <= Math.min(totalPages, currentPage + siblingCount); i++) {
      items.push(
        <button
          key={i}
          className={`${styles.pageButton} ${i === currentPage ? styles.active : ''}`}
          onClick={() => handleCurrentPage(i)}
        >
          {i}
        </button>
      );
    }

    if (currentPage < totalPages - siblingCount - 1) {
      items.push(
        ellipsis,
        <button
          key="last"
          className={styles.pageButton}
          onClick={() => handleCurrentPage(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return items;
  };

  return (
    <div className={styles.paginationContainer}>
      <button
        className={`${styles.navButton} ${currentPage === 1 ? styles.disabled : ''}`}
        onClick={() => handleCurrentPage(currentPage - 1)}
      >
        &laquo;
      </button>
      {renderPaginationItems()}
      <button
        className={`${styles.navButton} ${currentPage === totalPages ? styles.disabled : ''}`}
        onClick={() => handleCurrentPage(currentPage + 1)}
      >
        &raquo;
      </button>
    </div>
  );
};

export default PaginationComponent;


import axios from "axios";
import { getUrl } from "../../MIS/Global";


// Axios instance for making API calls (you can configure it as needed)
const apiClient = axios.create({
  baseURL: getUrl(),  // Replace with your API base URL
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// Fetch all items (GET request)
export const wishlistProduct_fetchItems = async (newData) => {
  let data={...newData}
  const queryParams = new URLSearchParams(data).toString();
  const response = await apiClient.get(`wishlist/?${queryParams}`);  
  return response?.data?.data;
};


// Add an item (POST request)
export const wishlistProduct_addItem = async (formData) => {      
  const response = await apiClient.post("wishlist/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data; // Return response data
  
};

// Delete an item (DELETE request)
export const wishlistProduct_deleteItem = async (newItem) => {    
  const queryParams = new URLSearchParams(newItem).toString();
  const response = await apiClient.delete(`wishlist/?${queryParams}`);
  return response.data;
};

import React, { useEffect, useState } from "react";
import "../../Rootlayout.css";
import { Outlet, useLocation } from "react-router-dom";
import { FooterOne, AppNavbar } from "../pages/ImportPages";
import { setMainProductFilterList } from "../../API/Reducer/mainProductReducer";
import { useDispatch } from "react-redux";
import styles from "./RootLayout.module.css"
import { deleteCookieFromDB, getCookieFromDB } from "../../MIS/indexedDBUtils";
import { showToast } from "../../API/Reducer/toastSlice";
import { handleUserSetLocalStorage } from "../../MIS/Global";
import { setUserLogin } from "../../API/Reducer/userReducer";

function RootLayout() {
  const location = useLocation();
  const dispatch=useDispatch();
  const handleClear=()=>{    
    dispatch(setMainProductFilterList([]));
  }
  useEffect(() => {
  window.scrollTo(0, 0);
    handleClear()
  }, [location]);

  useEffect(() => {      
    // Function to check the cookie
    const checkCookie = async () => {
      try {
        const cookie = await getCookieFromDB('jcUserToken');
        if (cookie) {
          const currentTime = Date.now(); // Get the current time in milliseconds
          if (cookie.expires <= currentTime) {                        
            showToast("Token has expired!", 'danger');
            await deleteCookieFromDB('jcUserToken'); 
            handleUserSetLocalStorage([],"userData",true)
            dispatch(setUserLogin());           
          }
        } else {
          await deleteCookieFromDB('jcUserToken');          
          handleUserSetLocalStorage([],"userData",true)
          dispatch(setUserLogin());
        }
      } catch (error) {
        showToast("Some thing went wrong; \nPlease contact your administrator!", 'danger');
      }
    };

    // Check cookie on mount
    checkCookie();

    // Set an interval to check the cookie every minute (60000 ms)
    const intervalId = setInterval(checkCookie, 600000);

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };

  },[location]);
  return (
    <>
    {/* <div className={styles?.layout_container}> */}
      <header className="user_nav">
        <AppNavbar />
      </header>
      <main >
        <Outlet />
      </main>
      <footer>
        <FooterOne />
      </footer>
    {/* </div> */}
    </>
  );
}

export default RootLayout;

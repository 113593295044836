import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import UserWishListCard from './UserWishListCard';

const UserWishListPage = ({close}) => {
  const { userWishList } = useSelector((state) => state.user);
  return (
    <>
    <Container>
      <Row>
      {
        userWishList.length > 0?
        (
          userWishList.map((element,index)=>{
            return(
              <>
              <Col key={element?.id} xs={12} className={`mb-1`}>
                <UserWishListCard
                  product={element}
                  path={`/tbkDetail/${element?.pid}/${element?.subid}`}
                  closeModal={close}

                />
              </Col>
              <hr/>
              </>
            )
          })
                  
        )
        :null
      }
       
      </Row>
    </Container>

    </>
  )
}

export default UserWishListPage
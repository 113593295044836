import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTbkProductsDetailQuery } from '../../../API/Model/useFilterModel';
import { Col, Container, Row, Tab, Nav, Button, Modal, Form, Spinner } from 'react-bootstrap';
import styles from './TbkProduct.module.css';
import { getImageUrl } from '../../../MIS/Global';
import SizePriceList from './SizePriceList';
import {
  FaExclamationTriangle,FaExclamationCircle,  
} from "react-icons/fa";

const TbkProductDetailPage = () => {
  const { id, subid } = useParams();
  const dispatch = useDispatch();
  const [products, setProducts] = useState();
  const [fabricData, setFabricData] = useState([]);
  const [sizeData, setSizeData] = useState([]);
  const [description, setDescription] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  let filterPara = {
    id: id,
    subid: subid,
    action: "getProductDetail",
  };

  const { refetch: refetchTbkProductDetail } = useTbkProductsDetailQuery(filterPara);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await refetchTbkProductDetail();
      setProducts(response?.data?.data?.ProductData || []);
      setFabricData(response?.data?.data?.FabricData || []);
      setSizeData(response?.data?.data?.SizeData || []);
      const productDescriptionData = response?.data?.data?.ProductData?.[0]?.subp_descp;
      let cleanedData = productDescriptionData
        .replace(/'/g, '"') // Replace single quotes with double quotes
        .replace(/: False/g, ': false') // Fix booleans
        .replace(/: True/g, ': true');
      let parsedData = JSON.parse(cleanedData);
      setDescription(parsedData || []);
      setIsError(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id && subid) {
      fetchData();
    }
  }, [id, subid]);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here (e.g., send data to server)
    console.log('Enquiry Submitted', formData);
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container fluid className={styles.container}>    
      <Row className={styles.row}>
      {isLoading ? (
      <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "250px" }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) :isError ? (
        <Col xs={12} className="d-flex justify-content-center mb-4">
            <div
              className={`${styles?.no_products_container} text-center`}
            >
              <FaExclamationCircle size={50} color="#f39c12" />
              <h2 className={styles?.no_products_text}>
                Oops! Something Went Wrong
              </h2>
              <p className={styles?.no_products_subtext}>
                We couldn't load the data. Please try again later!
              </p>
            </div>
          </Col>
      ) : products?.length > 0 ? (
        <>
        <Col xs={12} md={4} className={styles.imageContainer}>
          <img
            src={getImageUrl(`/media/${products?.[0]?.imgpath}`)}
            alt={products?.[0]?.id}
            className={styles.productImage}
          />
        </Col>
        <Col xs={12} md={8}>
          <Row>
            <Col xs={12} md={12} className={styles.section}>
              <h3 className={styles.heading}>{products?.[0]?.mpname}</h3>
              <h5 className={styles.subHeading}>{products?.[0]?.subpname}</h5>

              <h3 className={styles.heading}>Fabric</h3>
              <h5 className={styles.subHeading}>{fabricData?.[0]?.fabricname}</h5>

              <h3 className={styles.heading}>Description</h3>
              <Tab.Container id="description-tabs" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <Nav variant="tabs" className={styles.descriptionTabs}>
                      <Nav.Item>
                        <Nav.Link eventKey="first" className={styles.tabLink}>Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" className={styles.tabLink}>More Info</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className={styles.tabContent}>
                      <Tab.Pane eventKey="first">
                        <ul className={styles.descriptionList}>
                          {description.map((element, index) => (
                            <li key={index} className={styles.descriptionItem}>
                              {element?.description}
                            </li>
                          ))}
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <p className={styles.moreInfoText}>More detailed description content here...</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>

            <Col xs={12} md={12} className={styles.section}>
              <h3 className={styles.heading}>Size and Price</h3>
              <ul className={styles.sizePriceList}>
                {sizeData.map((item, index) => (
                  <li key={item.id} className={styles.sizePriceItem}>
                    <span className={styles.sizeName}>{item.sizename}</span>
                    <span className={styles.price}>₹{item.ps_whlprice}</span>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          {/* Enquiry Button */}
          <Row>
            <Col xs={12} md={12} className={`d-flex justify-content-end align-items-center`}>
          <Button
            variant="primary"
            className={styles.enquiryButton}
            onClick={handleModalShow}
          >
            Enquire Now
          </Button>
            </Col>
          </Row>
         
        </Col>
      </>
      ): products?.length <= 0 ? (
                <Col xs={12} className="d-flex justify-content-center mb-4">
                  <div
                    className={`${styles?.no_products_container} text-center`}
                  >
                    <FaExclamationTriangle size={50} color="#f39c12" />
                    <h2 className={styles?.no_products_text}>
                      Oops! No Products Available
                    </h2>
                    <p className={styles?.no_products_subtext}>
                      We couldn't find any products matching your selection. Try
                      searching for something else!
                    </p>
                  </div>
                </Col>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "250px" }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
      </Row>

      {/* Modal for Enquiry */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
        <SizePriceList sizeData={sizeData} />
        </Modal.Body>
        <Modal.Footer closeButton>
            <Button variant="primary" type="submit">
              Submit Enquiry
            </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TbkProductDetailPage;

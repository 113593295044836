
import axios from "axios";
import { getUrl } from "../../MIS/Global";
import { setMainProductLoading,setUserMainProductsAppend,setMainProductCount } from "../Reducer/mainProductReducer";

// Axios instance for making API calls (you can configure it as needed)
const apiClient = axios.create({
  baseURL: getUrl(),  // Replace with your API base URL
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// Fetch all items (GET request)
export const mainProduct_fetchItems = async () => {
  let data={page:1,limit:1000}
  const queryParams = new URLSearchParams(data).toString();
  const response = await apiClient.get(`mainproduct/?${queryParams}`);  
  return response?.data?.data;
};

export const mainProduct_filterItems = async (params) => {
  let data={...params}  
  const queryParams = new URLSearchParams(data).toString();
  const response = await apiClient.get(`sub-product/?${queryParams}`);  
  return response?.data;
};

export const mainProduct_fetchItems_user = async (pageParam = { last_id: 0, limit: 10 },dispatch) => {  
  try {
    dispatch(setMainProductLoading());
    const queryParams = new URLSearchParams(pageParam).toString();
    const response = await apiClient.get(`mainproducts/?${queryParams}`);           
    const newData = response?.data?.data || [];        
    dispatch(setMainProductCount(newData?.[0]?.total_records))
    const parsedData = newData.map((item) => ({
      ...item,
      products: JSON.parse(`[${item.products}]`), // Convert products string into a JSON array
    }));
    dispatch(setUserMainProductsAppend(parsedData));
  } catch (error) {
    
  } finally {
    dispatch(setMainProductLoading());
  }

};


// Add an item (POST request)
export const mainProduct_addItem = async (newItem) => {      
  if(newItem.get("id") > 0){    
    const response = await apiClient.patch("mainproduct/", newItem);  
    return response.data;
  }else{    
    delete newItem.id      
    const response = await apiClient.post("mainproduct/", newItem);  
    return response.data;
  }
  
};

// Delete an item (DELETE request)
export const mainProduct_deleteItem = async (newItem) => {    
  const queryParams = new URLSearchParams(newItem).toString();
  const response = await apiClient.delete(`mainproduct/?${queryParams}`);
  return response.data;
};

import { createSlice } from "@reduxjs/toolkit";
 
 
const mainProductReducer = createSlice({
  name: "mainproduct",
  initialState: {     
    mainProductLoading:false,    
    mainProductData:{},
    subProductLoading:false,    
    subProductData:{},
    mpid:0,
    catgid:0,
    subid:0,
    tel_mpid:0,    
    tel_subid:0,
    subDescription:{},
    userMainProducts:[],
    mainProductCount:0,
    mainProductFilterList:[],
    
  },

  reducers: {          
    setMainProductLoading(state, action) {      
      state.mainProductLoading = !state.mainProductLoading
    },    
    setMainProductData(state,action){
      state.mainProductData=action.payload
    },
    setSubProductLoading(state, action) {      
      state.subProductLoading = !state.subProductLoading
    },    
    setSubProductData(state,action){
      state.subProductData=action.payload
    },
    setMpId(state,action){
      state.mpid=action.payload
    },
    setCatgId(state,action){
      state.catgid=action.payload
    }, 
    setSubId(state,action){
      state.subid=action.payload
    }, 
    setTelMpId(state,action){
      state.tel_mpid=action.payload
    },   
    setTelSubId(state,action){
      state.tel_subid=action.payload
    },    
    setSubDescription(state,action){
      state.subDescription=action.payload
    },
    setUserMainProducts(state, action) {
      state.userMainProducts = action.payload; // Overwrite datas with new data
    },
    setUserMainProductsAppend(state, action) {
      const existingIds = new Set(state.userMainProducts.map((item) => item.catgid));
      const filteredNewData = action.payload.filter((item) => !existingIds.has(item.catgid));
      state.userMainProducts = [...state.userMainProducts, ...filteredNewData]; // Add only unique items
    },
    setMainProductCount(state,action){
      state.mainProductCount=action.payload;
    },
    setMainProductFilterList(state,action){
      state.mainProductFilterList=action.payload;
    }
  },
});

export const {
    setMainProductLoading,setMainProductData,setSubProductLoading,setSubProductData,
    setMpId,setCatgId,setSubDescription,setSubId,setTelMpId,setTelSubId,
    setUserMainProducts,setUserMainProductsAppend,setMainProductCount,
    setMainProductFilterList,
} = mainProductReducer.actions;
export default mainProductReducer.reducer;

import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import SearchField from "../../GlobalComponent/SearchField ";
import styles from "./CategoryFilter.module.css";
import { useDispatch, useSelector } from "react-redux";
import { mainProduct_filterItems } from "../../../API/Service/mainProductService";
import { useQuery } from "@tanstack/react-query";
import { setMainProductFilterList } from "../../../API/Reducer/mainProductReducer";
import { useParams } from "react-router-dom";
import { setFilterActive } from "../../../API/Reducer/filterReducer";
import { useMainProductFilterQuery } from "../../../API/Model/useFilterModel";
const CategoryFilter = () => {
  const { mpid } = useParams();
  const dispatch = useDispatch();
  const { mainProductFilterList } = useSelector((state) => state.mainproduct);
  const [searchTerm, setSearchTerm] = useState("");
  let filterPara = {
    page: 1,
    limit: 1000,
    action: "mainProductFilterList",

  };
  const [mainProducts, setMainProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { refetch: refetchMainProducts } = useMainProductFilterQuery(filterPara);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await refetchMainProducts();        
        setMainProducts(response?.data || []);
        setIsError(false);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [refetchMainProducts]);
   

  const handleCheckTrue = (id) => {
    let data=mainProductFilterList.length>0?mainProductFilterList:mainProducts?.data
    return data?.map((item) =>
      item.mpid == id ? { ...item, isCheck: !item.isCheck } : item
    );
  };

  const handleIsCheck = (id) => {
    dispatch(setMainProductFilterList(handleCheckTrue(id)));
    dispatch(setFilterActive());
  };

  useEffect(() => {
    if (mainProducts?.data?.length > 0) {
      dispatch(setMainProductFilterList(handleCheckTrue(mpid)));
    }
  }, [mainProducts]);

  const filteredMainProducts = mainProductFilterList.filter((element) =>
    element.mpname.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  return (
    <>
      <Container fluid>
        <section className="row">
          <aside className="col-12">
            <table className={"table table-sm"}>
              <thead>
                <th colSpan={2}>
                  <SearchField
                    placeholder="Search here..."
                    onSearch={handleSearch}
                    className={styles.inputSearch}
                  />
                </th>
              </thead>
              <tbody
                style={{
                maxHeight: "300px",
                overflowY: "auto",
                display: "block",
              }}
              >
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "250px" }}
                  >
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : isError ? (
                  <div className="text-danger text-center">
                    Failed to load data.
                  </div>
                ) : filteredMainProducts?.length > 0 ? (
                  filteredMainProducts?.map((element, index) => {
                    return (
                      <tr key={element?.mpid}>
                        <td
                          colSpan={2}
                          className="d-flex justify-content-start align-items-center gap-2"
                        >
                          <div className={`form-check ${styles?.checkCursor}`}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`defaultCheck${element?.mpid}`}
                              checked={element?.isCheck}
                              onChange={(e)=>{
                                handleIsCheck(element?.mpid)
                              }}
                            />
                            <label
                              className={`form-check-label ${styles?.checkCursor}`}
                              for={`defaultCheck${element?.mpid}`}
                            >
                              {element?.mpname} ({element?.prdCount})
                            </label>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="text-center">No Product types available.</div>
                )}
              </tbody>
            </table>
          </aside>
        </section>
      </Container>
    </>
  );
};

export default CategoryFilter;

import React, { useEffect, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { mainProduct_fetchItems_user } from "../../API/Service/mainProductService";
import LoadingSpinner from "../loader/LoadingSpinner";
import CategoryCard from "../User/Cards/CategoryCard";
import Testimonial from "../User/Testimonial/Testimonial";
import ShipDetail from "../User/Shipping/ShipDetail";
import BannerSlider from "../User/Slider/BannerSlider";
import { useNavigate } from "react-router-dom";
import { isSmallScreen } from "../../MIS/Global";

const Home = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { userMainProducts, mainProductLoading, mainProductCount } = useSelector((state) => state.mainproduct);
  let productBgColor="";
  const sectionRef = useRef(null); // Reference to the specific section

  useEffect(() => {
    mainProduct_fetchItems_user({ last_id: 0, limit: 100 }, dispatch);
  }, []);

   

  // const loadMoreData = useCallback(() => {
  //   if (mainProductCount === userMainProducts.length) return;
  //   if (mainProductLoading) return; // Prevent multiple requests
  //   const lastId = userMainProducts[userMainProducts.length - 1]?.catgid || 0;
  //   mainProduct_fetchItems_user({ last_id: lastId, limit: 100 }, dispatch);
  // }, [dispatch, userMainProducts, mainProductLoading]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting && !mainProductLoading) {
  //         loadMoreData();
  //       }
  //     },
  //     {
  //       root: null, // Default to the viewport
  //       rootMargin: "100px", // Trigger slightly before the section is fully in view
  //       threshold: 0.1, // When 10% of the section is visible
  //     }
  //   );

  //   const target = sectionRef.current;
  //   if (target) {
  //     observer.observe(target);
  //   }

  //   return () => {
  //     if (target) {
  //       observer.unobserve(target);
  //     }
  //   };
  // }, [loadMoreData, mainProductLoading]);

  
  return (
    <Container fluid>
      {/* Image Slider Section */}
      <Row className="mb-4">
        <Col xs={12}>
          <BannerSlider />
        </Col>
      </Row>

      {/* Specific Section for Main Scroll */}
      <Row>
        <Col xs={12} className="mb-4" style={{ padding: "0" }} ref={sectionRef}>
          {userMainProducts?.map((product, index) => {
            // Determine if the category name has changed
            let shouldPrintCategory =userMainProducts[userMainProducts.length - 1].catgname !== product.catgname;
            if(mainProductCount === userMainProducts.length){shouldPrintCategory=true;}
            if(productBgColor==""){
              productBgColor="#f3e6f8";
            }else if(productBgColor=="#f3e6f8"){
              productBgColor="#f9e2d4";
            }else if(productBgColor=="#f9e2d4"){
              productBgColor="#f7f5c1";
            }else if(productBgColor=="#f7f5c1"){
              productBgColor="#f3e6f8";
            }
            // Alternate row background color when the category changes
            const rowBackgroundColor = productBgColor;

            return (
              <Container
                key={product?.catgid}
                fluid
                style={{
                  backgroundColor: rowBackgroundColor,
                  padding:isSmallScreen()?"10px":"40px",
                  marginBottom: "20px",
                }}
              >
                {shouldPrintCategory && (
                  <Row className="mb-4">
                    <Col xs={12}>
                      <h2>{product.catgname}</h2>
                    </Col>
                  </Row>
                )}
                <Row>
                  {product?.products?.map((element) => (
                    <Col key={element?.id} xs={6} sm={6} md={4} lg={3} className="mb-4">
                      <CategoryCard product={element} path={`/subcategory/${element?.id}/${element?.productName}`}/>
                    </Col>
                  ))}
                </Row>
              </Container>
            );
          })}
        </Col>
      </Row>

      {/* Loading Spinner */}
      <Col xs={12} className="d-flex justify-content-center align-items-center">
        <LoadingSpinner isLoading={mainProductLoading} />
      </Col>

      {/* Additional Sections */}
      <Row className="mb-4">
        <Col xs={12}>
          <ShipDetail />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12}>
          <Testimonial />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;

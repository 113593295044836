import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './UserWishListCard.module.css'
import { getImageUrl } from '../../../MIS/Global'
import { FaRupeeSign,FaTrash } from "react-icons/fa";
import { useMutation } from '@tanstack/react-query';
import useToast from '../../../API/GlobalHooks/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { wishlistProduct_deleteItem } from '../../../API/Service/userWishlistService';
import { useNavigate } from 'react-router-dom';
import { setUserWishList } from '../../../API/Reducer/userReducer';

const UserWishListCard = ({product,path,closeModal}) => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const showToast = useToast();
  const { userWishList } = useSelector((state) => state.user);
  
  const deleteMutation = useMutation({
    mutationFn: wishlistProduct_deleteItem,
    onSuccess: (data, variables) => {
      const { id } = variables; 
      showToast("Remove this product from your wishlist?","success");
      const filteredProducts = userWishList.filter((product) => product.id !== id);
      dispatch(setUserWishList(filteredProducts));
    },
    onError: (error) => {    
      showToast("Failed to wishlist transaction; please try again.", 'danger');       
    },    
  });
  const handleDeleteWishlist=(e,id)=>{
    e.stopPropagation();
    deleteMutation.mutate({id:id});
  }
  return (
    <>
    <Container className={`${styles?.cursor_w}`}
    onClick={()=>{navigate(path);closeModal();}}
    >
      <Row >
      <Col xs={12} className={`${styles?.del_w}`}>
        <div className={`${styles?.del_icon_w}`}
        onClick={(e)=>{handleDeleteWishlist(e,product?.id)}}
        >
        {/* <FaTrash style={{fontSize:"12px",color:"#fff"}}/> */}
         {/* <span>&times;</span>
          */}
          X
        </div>
      </Col>
        <Col xs={6} lg={4} className={styles.imageContainer}>
        <img
            src={getImageUrl(`/media/${product.imgpath}`)}
            alt={product?.id}
            className={styles.productImage}
          />
        </Col>
        <Col xs={6} lg={8} className={`d-flex flex-column  gap-2`}>
          <div className={`d-flex flex-column gap-1`}>
          <span className={`${styles?.heading_w}`}>
           {product?.mpname}
          </span>
          <span className={`${styles?.subheading_w}`}>
          {product?.subpname}

          </span>
          </div>
      
          <span className={`${styles?.price_w} fw-bold d-flex align-items-center`}>
           From&nbsp;<FaRupeeSign/>{product?.min_wprice}
          </span>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default UserWishListCard
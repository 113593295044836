import React, { useState, useRef, useEffect } from 'react';
import { Col, Container, Image, Row, Spinner } from 'react-bootstrap';
import "../../../Components/pages/product.css";
import { FaTrash } from 'react-icons/fa';
import { getImageUrl } from '../../../MIS/Global';
import noImg from '../../../Assets/noimage.png';
import { useModal } from '../../ConfirmationModal/ModalContext';
import useToast from '../../../API/GlobalHooks/useToast';
import { tbkProduct_deleteItem } from '../../../API/Service/tbkProductService';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const TbkProductList = ({ data }) => {
  const queryClient = useQueryClient();
  const [visibleImages, setVisibleImages] = useState(new Set());
  const observer = useRef(null);
  const { showModal } = useModal();
  const showToast = useToast();

  const deleteMutation = useMutation({
    mutationFn: tbkProduct_deleteItem,
    onSuccess: () => {
      queryClient.invalidateQueries(["tbkProducts"]); // Refetch sizes after deletion
      showToast("Product deleted successfully", 'success');     
    },
    onError: (error) => {    
      showToast("Failed to delete product; please try again.", 'danger');       
    },    
  });

  useEffect(() => {
    // Initialize IntersectionObserver
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleImages((prev) => new Set([...prev, entry.target.dataset.index]));
            observer.current.unobserve(entry.target); // Stop observing once loaded
          }
        });
      },
      { rootMargin: "100px" }
    );

    return () => {
      // Cleanup the observer
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);
    
  const handleDeleteImage = (id) => {
    showModal({
        title: "Delete Confirmation",
        message: "Are you sure you want to delete this product?",
        onConfirm: () => {
          deleteMutation.mutate({id:id});
        },
        confirmText: "Yes",
        cancelText: "No",
      });
  };

  const handleImageRef = (element, index) => {
    if (element && observer.current) {
      element.dataset.index = index;
      observer.current.observe(element);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            {data?.isLoadingTbkProducts ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "250px" }}
              >
                <Spinner animation="border" />
              </div>
            ) : data?.isErrorTbkProducts ? (
              <div className="text-danger text-center">Failed to load data.</div>
            ) : data?.tbkProducts?.length > 0 ? (
              <div className="image-list-container">
                {data?.tbkProducts?.map((element, index) => (
                  <div
                    className="image-box"
                    key={index}
                    ref={(el) => handleImageRef(el, index)}
                  >
                    {visibleImages.has(String(index)) ? (
                      <Image
                        src={getImageUrl(`${element?.imgpath}`) || noImg}
                        className="uploaded-image"
                        alt={element?.productname}
                      />
                    ) : (
                      <div className="image-placeholder">Loading...</div>
                    )}
                    <div
                      className="delete-icon"
                      onClick={() => handleDeleteImage(element?.id)}
                    >
                      <FaTrash />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center">No products available.</div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TbkProductList;

import React, { useState } from "react";
import styles from "./SizePriceList.module.css";
import InputNormalEL from "../../GlobalComponent/Elements/InputNormalEL";

const SizePriceList = ({ sizeData }) => {
  const [quantities, setQuantities] = useState({});

  const handleQuantityChange = (id, value) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = () => {
    console.log("Quantities:", quantities);
  };

  return (
    <div className={styles.sizePriceContainer}>
      <ul className={styles.sizePriceList}>
        {sizeData.map((item) => (
          <li key={item?.id} className={styles.sizePriceItem}>
            <span className={styles.sizeName}>{item?.sizename}</span>
            <span className={styles.price}>₹{item?.ps_whlprice}</span>
            <InputNormalEL
              type="number"
              className={`${styles?.quantityInput}`}
              hideStepper={true}
              name={`qty${item?.id}`}
              textAlign = "end"
              events={{
                onChange:handleQuantityChange
              }}
              
            />
          
          </li>
        ))}
      </ul>
     
    </div>
  );
};

export default SizePriceList;

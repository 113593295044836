import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./Testimonial.css";
import "slick-carousel/slick/slick-theme.css";
import { FaBookmark } from "react-icons/fa";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { TfiQuoteLeft } from "react-icons/tfi";
import { Col, Container, Row } from "react-bootstrap";

 
const data = [
  {
    para: "Love it! It's knitted from extra fine mulesing-free Merino wool and really has kept its shape over time. Can't wait to buy some more colours and new awesome styles!",
    tag: FaBookmark,
    label: "LOLA DARK/PARIS",
  },
  {
    para: "Love it! It's knitted from extra fine mulesing-free Merino wool and really has kept its shape over time. Can't wait to buy some more colours and new awesome styles!",
    tag: FaBookmark,
    label: "LOLA DARK/PARIS",
  },
  {
    para: "Love it! It's knitted from extra fine mulesing-free Merino wool and really has kept its shape over time. Can't wait to buy some more colours and new awesome styles!",
    tag: FaBookmark,
    label: "LOLA DARK/PARIS",
  },
  {
    para: "Love it! It's knitted from extra fine mulesing-free Merino wool and really has kept its shape over time. Can't wait to buy some more colours and new awesome styles!",
    tag: FaBookmark,
    label: "LOLA DARK/PARIS",
  },
];
const Testimonial = () => {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col
            sm={1} xs={2}
            className="d-flex justify-content-start align-items-center"
          >
            <MdArrowBackIos
              className="slick-arrow"
              style={{ fontWeight: "100" }}
              onClick={previous}
            />
          </Col>
          <Col sm={10} xs={8}>
            <div className="">
              <Slider
                ref={(slider) => {
                  sliderRef = slider;
                }}
                {...settings}
              >
                {data.map((item, index) => (
                  <div key={index} className="slider-main">
                    <div>
                      <TfiQuoteLeft className="quote-icon" />
                    </div>
                    <div className="slider-text">{item?.para}</div>
                    <div>
                      <FaBookmark />
                    </div>
                    <div>{item?.label}</div>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
          <Col sm={1} xs={2} className="d-flex justify-content-end align-items-center">
            <MdArrowForwardIos
              className="slick-arrow"
              style={{ fontWeight: "100" }}
              onClick={next}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Testimonial;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRandomNumberInRange } from "../../../MIS/Global";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useTbkProductsQuery } from "../../../API/Model/useFilterModel";
import styles from "../SubCategory/UserSubCategoryPage.module.css";
import {
  FaExclamationTriangle,
  FaExclamationCircle,
  FaFilter,
} from "react-icons/fa"; // Example icon
import CategoryCard from "../Cards/CategoryCard";
import PaginationComponent from "../Pagination/PaginationComponent";

const TbkProductPage = () => {
  const { subid } = useParams();
  const dispatch = useDispatch();
  const { mainProductFilterList } = useSelector((state) => state.mainproduct);
  const { paginationRowNumber } = useSelector((state) => state.filterProduct);
  const { userWishList } = useSelector((state) => state.user);

  const [lastId, setLastId] = useState(0);
  const [limit, setLimit] = useState(getRandomNumberInRange(25, 80));
  const [checkedMpids, setCheckedMpids] = useState([]);
  const [checkedSubids, setCheckedSubids] = useState([]);
  const [products, setProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isChange, setIsChange] = useState(false);

  let filterPara = {
    last_id: lastId,
    limit: limit,
    action: "getProducts",
    mpid: checkedMpids,
    subid: [subid],
  };
  const options = [
    { value: "bs", label: "Best Selling" },
    { value: "tr", label: "Best Trending" },
    { value: "asc", label: "Name Ascending" },
    { value: "dsc", label: "Name Descending" },
  ];

  const { refetch: refetchTbkProducts } = useTbkProductsQuery(filterPara);
  const handleWishlistData=(data)=>{
    if(data==undefined || data.length<=0){return}
    let wishlistIds =[];
    let updatedProducts=[];
    if(userWishList.length>0){
     wishlistIds = new Set(userWishList.map(item => item.pid));
    }  
    if(userWishList.length>0){
      updatedProducts = data.map(product => ({
        ...product,
        isWishlist: wishlistIds.has(product.id) || false,
      }));
    }else{
      updatedProducts = data.map(product => ({
        ...product,
        isWishlist: false,
      }));
    }
    
    setProducts(updatedProducts);
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await refetchTbkProducts();
      
      handleWishlistData(response?.data?.data || []);
      // setProducts(response?.data?.data || []);
      setIsError(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (subid) {
      fetchData();
    } else if (paginationRowNumber?.firstRow > 0) {
      fetchData();
    }
  }, [subid]);

  useEffect(() => {
    if (paginationRowNumber?.firstRow > 0) {
      if (lastId !== paginationRowNumber?.firstRow) {
        setLastId(
          paginationRowNumber?.firstRow === 1
            ? 0
            : paginationRowNumber?.firstRow
        );
      }
    }
  }, [paginationRowNumber]);

  useEffect(() => {
    handleWishlistData(products);
  }, [userWishList]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={3} xs={2}></Col>
          <Col lg={9} xs={12}>
            <Row className="mb-4 d-flex align-items-center">
              <Col xs={6} lg={9}>
                <h6>Showing all ({products?.length || 0}) results</h6>
              </Col>
              <Col xs={6} lg={3}>
                <Select options={options} defaultValue={options[0]} />
              </Col>
            </Row>
            <Row className="p-4">
              {isLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "250px" }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : isError ? (
                <Col xs={12} className="d-flex justify-content-center mb-4">
                  <div
                    className={`${styles?.no_products_container} text-center`}
                  >
                    <FaExclamationCircle size={50} color="#f39c12" />
                    <h2 className={styles?.no_products_text}>
                      Oops! Something Went Wrong
                    </h2>
                    <p className={styles?.no_products_subtext}>
                      We couldn't load the data. Please try again later!
                    </p>
                  </div>
                </Col>
              ) : products?.length > 0 ? (
                products?.map((element) => (
                  <Col
                    key={element?.id}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="mb-4"
                  >
                    <CategoryCard
                      product={element}
                      path={`/tbkDetail/${element?.id}/${element?.subid}`}
                      wishlist={true}
                      price={true}
                    />
                  </Col>
                ))
              ) : products?.length <= 0 ? (
                <Col xs={12} className="d-flex justify-content-center mb-4">
                  <div
                    className={`${styles?.no_products_container} text-center`}
                  >
                    <FaExclamationTriangle size={50} color="#f39c12" />
                    <h2 className={styles?.no_products_text}>
                      Oops! No Products Available
                    </h2>
                    <p className={styles?.no_products_subtext}>
                      We couldn't find any products matching your selection. Try
                      searching for something else!
                    </p>
                  </div>
                </Col>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "250px" }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Row>
            <Row>
              <Col
                xs={12}
                className={`d-flex justify-content-end align-items-center mb-4 ${
                  products?.length > 0 ? "" : "d-none"
                }`}
              >
                <PaginationComponent
                  totalPages={products?.[0]?.total_pages}
                  limits={limit}
                  totalRows={products?.[0]?.total_rows}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TbkProductPage;

import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Col, Container, Offcanvas, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import CategoryCard from "../Cards/CategoryCard";
import FilterPage from "../Filter/FilterPage";
import { useDispatch, useSelector } from "react-redux";
import { FaExclamationTriangle,FaExclamationCircle,FaFilter  } from "react-icons/fa"; // Example icon
import styles from './UserSubCategoryPage.module.css';
import PaginationComponent from "../Pagination/PaginationComponent";
import { useSubProductsQuery } from "../../../API/Model/useFilterModel";
import {getRandomNumberInRange} from "../../../MIS/Global.js"

const UserSubCategoryPage = () => {
  const { mpid, mpname } = useParams();
  const dispatch=useDispatch();
  const { mainProductFilterList } = useSelector((state) => state.mainproduct);  
  const { paginationRowNumber } = useSelector((state) => state.filterProduct);  
  
  const [lastId, setLastId] = useState(0)
  
  const [limit, setLimit] = useState(getRandomNumberInRange(25,80))

  const [checkedMpids, setCheckedMpids] = useState([]);
  const [subProducts, setSubProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isChange, setIsChange] = useState(false);

  let filterPara = {
    last_id: lastId,
    limit: limit,
    action: "subproduct",
    mpid:checkedMpids,

  };
  const options = [
    { value: "bs", label: "Best Selling" },
    { value: "tr", label: "Best Trending" },
    { value: "asc", label: "Name Ascending" },
    { value: "dsc", label: "Name Descending" },
  ];

  const { refetch: refetchSubProducts } = useSubProductsQuery(filterPara);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await refetchSubProducts();        
      setSubProducts(response?.data?.data || []);
      setIsError(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  

  const getCheckedMpids = (data) => {
    return data
      .filter((item) => item.isCheck) // Filter items where isCheck is true
      .map((item) => item.mpid); // Extract mpid from the filtered items
  };

 
  useEffect(() => { 
    if (checkedMpids.length > 0) {      
      fetchData();
    }else if(isChange===true){
      fetchData();
      setIsChange(false)
    }else if(paginationRowNumber?.firstRow > 0){
      fetchData();
    }   
  }, [checkedMpids,isChange,lastId]);

  useEffect(() => { 
   if(paginationRowNumber?.firstRow > 0){
      if(lastId !== paginationRowNumber?.firstRow){
        setLastId(paginationRowNumber?.firstRow === 1?0:paginationRowNumber?.firstRow)
      }      
    }   
  }, [paginationRowNumber]);
  

  useEffect(() => {
    if (mainProductFilterList?.length > 0) {
      const newCheckedMpids = getCheckedMpids(mainProductFilterList);  
      // Only update `checkedMpids` and call `fetchData` if there's a change             
        setCheckedMpids(newCheckedMpids);
        if(newCheckedMpids.length<=0){
          setIsChange(true)
        }      
    }
     
  }, [mainProductFilterList]);    
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={3} xs={2}>
           <div className={styles?.hide_on_sm}>
            <FilterPage/>
           </div>
            <div className={styles?.hide_on_lg}>
            <button className={styles?.filter_button} type="button" onClick={handleShow}>
              <FaFilter className={styles?.filter_icon}/>
              <span className={styles?.filter_text}>Filter</span>
            </button>
           </div>
          </Col>
          <Col lg={9} xs={12}>
            <Row className="mb-4 d-flex align-items-center">
              <Col xs={6} lg={9}>
                <h6>Showing all ({subProducts?.length || 0}) results</h6>
              </Col>
              <Col xs={6} lg={3}>
                <Select options={options} defaultValue={options[0]} />
              </Col>
            </Row>
            <Row className="p-4">

              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : isError ? (
                <Col xs={12} className="d-flex justify-content-center mb-4">
                <div className={`${styles?.no_products_container} text-center`}>
                  <FaExclamationCircle size={50} color="#f39c12" />
                  <h2 className={styles?.no_products_text}>Oops! Something Went Wrong</h2>
                  <p className={styles?.no_products_subtext}>We couldn't load the data. Please try again later!</p>
                </div>
                </Col>               
              ) : subProducts?.length > 0 ? (
                subProducts?.map((element) => (
                  <Col key={element?.id} xs={12} sm={12} md={4} lg={4} className="mb-4">
                    <CategoryCard product={element} path={`/tbk/${element?.id}`} />
                  </Col>
                ))
              ) : subProducts?.length<=0 ?(
                <Col xs={12} className="d-flex justify-content-center mb-4">
                <div className={`${styles?.no_products_container} text-center`}>
                  <FaExclamationTriangle size={50} color="#f39c12" />
                  <h2 className={styles?.no_products_text}>Oops! No Products Available</h2>
                  <p className={styles?.no_products_subtext}>We couldn't find any products matching your selection. Try searching for something else!</p>
                </div>
                </Col>
              ): (<div className="d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                  <Spinner animation="border" variant="primary" />
                </div>)}
            </Row>
            <Row>
              <Col xs={12}
               className={`d-flex justify-content-end align-items-center mb-4 ${subProducts?.length > 0 ? '':"d-none"}`}>
               <PaginationComponent 
                totalPages={subProducts?.[0]?.total_pages} 
                limits={limit}
                totalRows={subProducts?.[0]?.total_rows}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <FilterPage/>
        </Offcanvas.Body>
      </Offcanvas>
      </Container>
    </>
  );
};

export default UserSubCategoryPage;

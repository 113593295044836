import React from "react";
 
import { BsBoxSeam } from "react-icons/bs";
import { GrTransaction } from "react-icons/gr";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { CiDollar } from "react-icons/ci";
import "./ShipDetail.css";
import { Col, Container, Row } from "react-bootstrap";
function ShipDetail() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={3} md={6} sm={12} className="p-4">
            <Row>
              <Col xs="auto">
                <div className="img1">
                  <BsBoxSeam className="bouncer-icon"/>
                </div>
              </Col>
              <Col>
                <div className="text">
                  <h6>Free Shipping</h6>
                  <p>
                     Shop now and take advantage of free delivery options.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={6} sm={12} className="p-4">
            <Row>
              <Col xs="auto">
                <div className="img1">
                    <CiDollar className="bouncer-icon"/>
                </div>
              </Col>
              <Col>
                <div className="text">
                  <h6>Money Bank Guarantee</h6>
                  <p>
                    Enjoy a 100% Money-Back Guarantee on your purchase.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={6} sm={12} className="p-4">
            <Row>
              <Col xs="auto">
                <div className="img1">
                 <FaMoneyCheckDollar className="bouncer-icon"/>
                </div>
              </Col>
              <Col>
                <div className="text">
                  <h6>Flexible Payment</h6>
                  <p>
                    Affordable and flexible payment options available.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={6} sm={12} className="p-4">
            <Row>
              <Col xs="auto">
                <div className="img1">
                    <GrTransaction className="bouncer-icon"/>
                </div>
              </Col>
              <Col>
                <div className="text">
                  <h6>Exchange and Returns</h6>
                  <p>
                    Your satisfaction matters—easy exchange and return available.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ShipDetail;

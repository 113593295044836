import { createSlice } from "@reduxjs/toolkit";
 
 
const filterReducer = createSlice({
  name: "filterProduct",
  initialState: {     
    filterActive:false,
    paginationRowNumber:{firstRow:0,lastRow:0}, 
  },

  reducers: {          
    setFilterActive(state, action) {      
      state.filterActive = !state.filterActive
    },
    setPaginationRowNumber(state,action){
      state.paginationRowNumber =action.payload
    }    
    
  },
});

export const {
   setFilterActive,setPaginationRowNumber
} = filterReducer.actions;
export default filterReducer.reducer;

import React, { useEffect, useState } from "react";
import { AdminNavbar } from "../pages/ImportPages";
import { Outlet, useNavigate } from "react-router-dom";
import { getCookie, handleUserSetLocalStorage, isTokenExpired } from "../../MIS/Global";
import { deleteCookieFromDB, getCookieFromDB } from "../../MIS/indexedDBUtils";
import useToast from "../../API/GlobalHooks/useToast";

function AppAdminLayout() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(""); // Use state to control the login visibility
  const showToast = useToast();
  // Update login visibility based on pathname
  useEffect(() => {
    const path = window.location.pathname;
    setIsLogin(path === "/admin" ? "d-none" : ""); // Update the state, not the variable directly

    if (path === "/admin") {
      return; // Skip cookie check if on admin page
    }

    // Function to check the cookie
    const checkCookie = async () => {
      try {
        const cookie = await getCookieFromDB('jcToken');
        if (cookie) {
          const currentTime = Date.now(); // Get the current time in milliseconds
          if (cookie.expires <= currentTime) {                        
            showToast("Token has expired!", 'danger');
            await deleteCookieFromDB('jcToken');
            handleUserSetLocalStorage([],"userData",true)
            navigate('/admin')
          }
        } else {
          await deleteCookieFromDB('jcToken');
          handleUserSetLocalStorage([],"userData",true)
          navigate('/admin')
        }
      } catch (error) {
        showToast("Some thing went wrong; \nPlease contact your administrator!", 'danger');
      }
    };

    // Check cookie on mount
    checkCookie();

    // Set an interval to check the cookie every minute (60000 ms)
    const intervalId = setInterval(checkCookie, 600000);

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };

  }, [navigate]);

  return (
    <>
      <header className={`${isLogin}`}>
        <AdminNavbar />
      </header>
      <main>
        <Outlet />
      </main>
      <footer>{/* <AdminFooter /> */}</footer>
    </>
  );
}

export default AppAdminLayout;

import axios from "axios";
import { getUrl } from "../../MIS/Global";

// Axios instance for making API calls (you can configure it as needed)
const apiClient = axios.create({
  baseURL: getUrl(),  // Replace with your API base URL
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const userSignin = async (pageParam) => {    
    pageParam={...pageParam,action:"signin"}
    const queryParams = new URLSearchParams(pageParam).toString();
    const response = await apiClient.get(`user-login/?${queryParams}`);  
    return response?.data;
};
import React from "react";
import styles from "./InputNormalEL.module.css"; // Import for optional custom styling

const InputNormalEL = ({
  label = "",
  type = "text",
  id = "",
  name = "",
  placeholder = "",
  startIcon = null,
  endIcon = null,
  startIconBgColor = "", // Background color for start icon
  endIconBgColor = "", // Background color for end icon
  readOnly = false,
  disabled = false,
  hideStepper = false,
  className = "",
  labelClassName = "",
  inputClassName = "",
  wrapperClassName = "",
  textAlign = "start", // Default text alignment
  maxLength = null, // Maximum character length
  error = "", // Error message for Formik
  touched = false, // Touched state from Formik
  events = {}, // To attach any custom events dynamically
}) => {
  // Resolve text alignment
  const textAlignStyle =
    textAlign === "start"
      ? "left"
      : textAlign === "center"
      ? "center"
      : textAlign === "end"
      ? "right"
      : "left"; // Default to left if no match

  // Handle maxLength and custom input handling
  const handleInput = (e) => {
    const { value } = e.target;
    if (type === "number" && maxLength && value.length > maxLength) {
      e.target.value = value.slice(0, maxLength); // Enforce maxLength for number inputs
    }
    if (events.onInput) {
      events.onInput(e); // Trigger custom onInput if provided
    }
  };

  return (
    <div className={`form-group position-relative ${wrapperClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className={`form-label mb-0 ${styles?.label} ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <div className="input-group">
        {startIcon && (
          <span
            className="input-group-text"
            id={`${id}-start-icon`}
            style={{ backgroundColor: startIconBgColor }}
          >
            {startIcon}
          </span>
        )}
        <input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          style={{
            textAlign: textAlignStyle, // Apply dynamic text alignment
          }}
          className={`form-control ${
            hideStepper && type === "number" ? styles.noStepper : ""
          } ${inputClassName} ${touched && error ? styles.errorInput : ""}`}
          readOnly={readOnly}
          disabled={disabled}
          maxLength={type !== "number" ? maxLength : null} // Apply maxLength only for non-number types
          {...events} // Spread all custom event handlers dynamically
          onInput={handleInput} // Override to handle maxLength
        />
        {endIcon && (
          <span
            className="input-group-text"
            id={`${id}-end-icon`}
            style={{ backgroundColor: endIconBgColor }}
          >
            {endIcon}
          </span>
        )}
      </div>
      {touched && error && (
        <div className={`text-danger ${styles.errorText}`}>{error}</div>
      )}
    </div>
  );
};

export default InputNormalEL;

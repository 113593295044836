import { Route, Routes } from "react-router-dom";
import RootLayout from "./Components/Layout/RootLayout";
import { AdminLogin, Home,UserSubCategoryPage,TbkProductPage,TbkProductDetailPage } from "./Components/pages/ImportPages";
import AppAdminLayout from "./Components/Layout/AppAdminLayout";
import AdminContent from "./Components/pages/AdminContent";
import GlobalToast from "./Components/loader/GlobalToast";
function App() {
  return (
    <>
    <GlobalToast/>
    <Routes>
      
      {/* This is user layout routes */}
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="/subcategory/:mpid?/:mpname?" element={<UserSubCategoryPage/>}/>
        <Route path="/tbk/:subid?" element={<TbkProductPage/>}/>
        <Route path="/tbkDetail/:id?/:subid?" element={<TbkProductDetailPage/>}/>
        
      </Route>
      
      <Route path="/admin" element={<AppAdminLayout />}>
        <Route index element={<AdminLogin />}/>        
        <Route path="product" element={<AdminContent />}/>        
      </Route>
    </Routes>
    </>
  );
}

export default App;

import { useQuery } from "@tanstack/react-query";
import { wishlistProduct_fetchItems } from "../Service/userWishlistService";
 
const useWishlistModel = (queryKey, queryFn, options = {}) => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey,
    queryFn,
    refetchOnWindowFocus: false,
    ...options,
  });

  return { data, isLoading, isError, refetch };
};

export const useWishlistQuery = (filterPara) => {
  return useWishlistModel(
    ["userWishlist", filterPara],
    () => wishlistProduct_fetchItems(filterPara)
  );
};

 

 
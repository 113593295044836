import * as Yup from "yup";

const useLoginConfig = () => {
  const validationSchema = Yup.object({
    mob: Yup.string().required("Mobile number is required")
                     .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),    
    upaswd: Yup.string().required("Password is required"),    
  });

  const initialValues = {
    id:0,
    mob: "",
    upaswd: "",
  };

  return { validationSchema, initialValues };
};

export default useLoginConfig;

import React from 'react';
import { Accordion } from 'react-bootstrap';
import styles from './FilterPage.module.css';
import CategoryFilter from './CategoryFilter';

const FilterPage = () => {
  return (
    <Accordion defaultActiveKey={['0']} alwaysOpen className={styles.accordion}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.accordionHeader}>
          <span className={styles.headerText}>Product Type</span>
        </Accordion.Header>
        <Accordion.Body className={styles.accordionBody}>
          <CategoryFilter/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FilterPage;

import React from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import "../../AdminLayout.css";
import { FaCircleUser } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";
import { setFabricModal, setSizeModal } from "../../API/Reducer/fabricReducer";
import { useDispatch } from "react-redux";
import FabricMaster from "../../Components/Fabric/FabricMaster"
import SizeMaster from "../../Components/Fabric/SizeMaster"
import { setProductCatgModal } from "../../API/Reducer/productCategoryReducer";
import CategoryPage from "../Admin/Category.jsx/CategoryPage";
import { CiLogout } from "react-icons/ci";
import { deleteCookie, handleUserSetLocalStorage } from "../../MIS/Global";
import { deleteCookieFromDB } from "../../MIS/indexedDBUtils";

function AdminNavbar() {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  return (
    <>
      <Navbar bg="warning" expand={false} className="admin-layout">
        <Container fluid>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"            
            style={{ width: "250px" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                Teeblanka 
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
             <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
             <li className="nav-item"
              onClick={() => dispatch(setProductCatgModal())}
              >
                <span className="nav-link active pointer" aria-current="page">Category</span>
              </li>
              <li className="nav-item" onClick={() => dispatch(setFabricModal())}>
                <span className="nav-link active pointer" aria-current="page">Fabric</span>
              </li>
              <li className="nav-item"
              onClick={() => dispatch(setSizeModal())}
              >
                <span className="nav-link active pointer" aria-current="page">Size</span>
              </li>
             </ul>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <CiLogout onClick={async (e)=>{await deleteCookieFromDB('jcToken');
          handleUserSetLocalStorage([],"udata",true)
          navigate("/admin")}}
          style={{cursor:"pointer",fontSize:"25px"}} />
        </Container>
      </Navbar>
      <FabricMaster/>
      <SizeMaster/>
      <CategoryPage/>
    </>
  );
}

export default AdminNavbar;

import { useQuery } from "@tanstack/react-query";
import { mainProduct_filterItems } from "../Service/mainProductService";
import { subProduct_fetchItemPost } from "../Service/subProductService";
import { tbkProduct_fetchItemPost, tbkProduct_getItems } from "../Service/tbkProductService";
 
const useFilterModel = (queryKey, queryFn, options = {}) => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey,
    queryFn,
    refetchOnWindowFocus: false,
    ...options,
  });

  return { data, isLoading, isError, refetch };
};

export const useMainProductFilterQuery = (filterPara) => {
  return useFilterModel(
    ["mainProductsFilter", filterPara],
    () => mainProduct_filterItems(filterPara)
  );
};

export const useSubProductsQuery = (filterPara) => {
  return useFilterModel(
    ["subProducts", filterPara],
    () => subProduct_fetchItemPost(filterPara)
  );
};

export const useTbkProductsQuery = (filterPara) => {
  return useFilterModel(
    ["tbkProducts", filterPara],
    () => tbkProduct_fetchItemPost(filterPara)
  );
};

export const useTbkProductsDetailQuery = (filterPara) => {
  return useFilterModel(
    ["tbkProductDetail", filterPara],
    () => tbkProduct_getItems(filterPara)
  );
};

 
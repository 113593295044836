import React, { useEffect, useState } from "react";
import {
  Navbar,
  Offcanvas,
  Nav,
  Container,
  OverlayTrigger,
  Tooltip,
  Modal,
  Badge,
} from "react-bootstrap";
import { FaRegUser } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import "./AppNavbar.css";
import UserLoginForm from "../User/UserLogin/UserLoginForm";
import {
  handleCheckUserLogin,
  handleUserSetLocalStorage,
} from "../../MIS/Global";
import { deleteCookieFromDB } from "../../MIS/indexedDBUtils";
import { MdLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setUserLogin, setUserLoginModal, setUserWishList } from "../../API/Reducer/userReducer";
import { useWishlistQuery } from "../../API/Model/useWishlistModel";
import UserWishListPage from "../User/UserWishlist/UserWishListPage";

function AppNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState("Home");
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [smShow, setSmShow] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [show, setShow] = useState(false);

  const { userLogin,userLoginModal,userWishList} = useSelector((state) => state.user);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const filterData={
    loginid:handleCheckUserLogin("userData","id"),
    prod_type:0,
   }
  const { refetch: refetchWishlistProducts } = useWishlistQuery(filterData);
  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      // Scrolling down
      setIsVisible(false);
    } else {
      // Scrolling up
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  const handleSetActiveLink = (link, path = "") => {
    setActiveLink(link);
    navigate(`/${path}`);
  };

  const renderTooltip = (props, title = "Simple Tooltip") => (
    <Tooltip id="button-tooltip" {...props}>
      {title}
    </Tooltip>
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleLogOut = async () => {
    await deleteCookieFromDB("jcUserToken");
    handleUserSetLocalStorage([], "userData", true);
    dispatch(setUserLogin());
    dispatch(setUserWishList([])) 
  };

  useEffect(() => {
    if (handleCheckUserLogin("userData", "id") !== 0) {
      setIsLogin(true);
    }
    else {
      setIsLogin(false);
    }
  },[userLogin,location,userLoginModal]);

  useEffect(() => {
    if(userLoginModal===true){      
      setSmShow(true)
      dispatch(setUserLoginModal())      
    } 
  },[userLoginModal]);

  const handleGetWishlist = async() => {   
    let isLogin= handleCheckUserLogin("userData", "id");
    if(isLogin<=0){return;}
    try {
      const response = await refetchWishlistProducts();
      dispatch(setUserWishList(response?.data))      
    } catch (error) {
      
    } finally {
       
    }
  };
  useEffect(() => {
    handleGetWishlist()
  },[isLogin]);

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        fixed="top"
        className={`shadow-sm pt-3 pb-3 app-navbar ${
          isVisible ? "visible" : "hidden"
        }`}
      >
        <Container fluid>
          <Navbar.Brand href="#" className="me-auto">
            Teeblanka
          </Navbar.Brand>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="top"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                Teeblanka
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3">
                <Nav.Link
                  // href="/"
                  active={activeLink === "Home"}
                  onClick={() => handleSetActiveLink("Home")}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  // href="/shop"
                  active={activeLink === "Shop"}
                  onClick={() => handleSetActiveLink("Shop")}
                >
                  Shop
                </Nav.Link>
                <Nav.Link
                  // href="/aboutus"
                  active={activeLink === "Product"}
                  onClick={() => handleSetActiveLink("Product")}
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  // href="/contact"
                  active={activeLink === "Pages"}
                  onClick={() => handleSetActiveLink("Pages")}
                >
                  Contact Us
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>

          {/* User Icon - Always Visible */}
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) =>
              renderTooltip(props, isLogin === false ? "Login" : "Logout")
            }
          >
            {isLogin === false ? (
              <div                
                onClick={() => setSmShow(true)}
                style={{ border: "none", backgroundColor: "transparent",cursor:"pointer" }}
                
              >
                <FaRegUser style={{fontSize:"20px"}}/>
              </div>
            ) : (
              <div
                
                onClick={()=> {
                  handleLogOut();
                }}
                style={{ border: "none", backgroundColor: "transparent",cursor:"pointer" }}
                
              >
                <MdLogout style={{fontSize:"20px"}}/>
              </div>
            )}
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => renderTooltip(props, "Wishlist")}
          >
            <div
              onClick={() => {
                handleShow();
              }}
              style={{ border: "none", backgroundColor: "transparent",cursor:"pointer" }}              
            >
              <FaRegHeart style={{fontSize:"20px"}} className="ms-3" />
              <sup>
              <Badge               
              bg="danger" className="rounded">
                {userWishList.length>99?"99+":userWishList.length}
              </Badge></sup>  
            </div>
          </OverlayTrigger>

          <Navbar.Toggle aria-controls="offcanvasNavbar" />
        </Container>
      </Navbar>

      <Modal size="md" show={smShow} onHide={() => setSmShow(false)}>
        <Modal.Body>
          <UserLoginForm modalClose={setSmShow} />
        </Modal.Body>
      </Modal>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Wishlist</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <UserWishListPage close={handleClose}/>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AppNavbar;

import React, { useState } from "react";
import "./CategoryCard.css";
import { FaHeart, FaEye,FaRupeeSign} from "react-icons/fa";
import { Tooltip, OverlayTrigger, Card } from "react-bootstrap";
import { getImageUrl, handleCheckUserLogin } from "../../../MIS/Global";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserLoginModal, setUserWishList } from "../../../API/Reducer/userReducer";
import useToast from "../../../API/GlobalHooks/useToast";
import { useMutation } from "@tanstack/react-query";
import { wishlistProduct_addItem, wishlistProduct_deleteItem } from "../../../API/Service/userWishlistService";
import { useWishlistQuery } from "../../../API/Model/useWishlistModel";

const CategoryCard = ({ product, 
  wishlist = false, quickView = false,path,
  price=false
}) => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const showToast = useToast();
  
  const filterData={
   loginid:handleCheckUserLogin("userData","id"),
   prod_type:0,
  }
  const { userWishList } = useSelector((state) => state.user);
  const { refetch: refetchWishlistProducts } = useWishlistQuery(filterData);
  const renderTooltip = (props, title = "Simple Tooltip") => (
    <Tooltip id="button-tooltip" {...props}>
      {title}
    </Tooltip>
  );
  const handleGetWishlist = async(values) => {    
    try {
      const response = await refetchWishlistProducts();
      dispatch(setUserWishList(response?.data))      
    } catch (error) {
      
    } finally {
       
    }
  };
  const wishlistMutation = useMutation({
    mutationFn: wishlistProduct_addItem,
    onMutate: () => {      
      
    },
    onSuccess: async (resData) => {                 
      if(resData?.code==200 && Object.keys(resData?.data).length>0){                        
        showToast("Added to wishlist! ❤️", 'success');                            
        handleGetWishlist()
      }else if(resData?.code==200 && resData?.data==-1){        
        showToast("Invalid credentials! Please try again", 'danger');        
      }
      else{                
        showToast("something went wrong; Please try again!", 'danger');       
      }        
    },
    onError: (error) => {            
      showToast("Some thing went wrong; \nPlease contact your administrator!", 'danger');     
    },
    onSettled: () => {
      
    },
  });

  const deleteMutation = useMutation({
    mutationFn: wishlistProduct_deleteItem,
    onSuccess: () => {
      handleGetWishlist()
    },
    onError: (error) => {    
      showToast("Failed to wishlist transaction; please try again.", 'danger');       
    },    
  });
  const handleWishlist=(e,pid)=>{
    e.stopPropagation()
    let isLogin=handleCheckUserLogin("userData","id");
    if(isLogin<=0){
      dispatch(setUserLoginModal())
    }else{
      let isExist=false;
      let filteredById=[];
      if(userWishList.length>0
        ){          
          filteredById = userWishList.filter(item => item.pid === pid);
          if(filteredById.length>0){
            isExist=true;
          }else{isExist=false;}
        }
    if(isExist===false){
      const data = { pid: pid, loginid: isLogin, prod_type: 0 };
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });
      
      wishlistMutation.mutate(data);
    }else{
      let id=filteredById?.[0]?.id;
      deleteMutation.mutate({id:id});
    }
 
           
    }
    
  }
  return (
    <>
      <div className="card product-card shadow-sm" onClick={()=>{
        navigate(path);
      }}>
      <div className="card-img-container">
        <img
          src={getImageUrl(`/media/${product?.imgpath}`)}
          alt={product?.id}
          className="card-img-top"
        />
        {/* Wishlist and Quick View Buttons */}
        <div className={`${product?.isWishlist===true?"action-buttons_fixed":"action-buttons"} `}>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            title="Add to Wishlist"
            overlay={(props) => renderTooltip(props, "Add to Wishlist")}
          >
            <div onClick={(e)=>{handleWishlist(e,product?.id)}}
              className={`action-button ${product?.isWishlist===true?"action-button_active":""}
              ${wishlist ? "" : "d-none"}`}>
              <FaHeart />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            title="Add to Wishlist"
            overlay={(props) => renderTooltip(props, "Quick View")}
          >
            <div className={`action-button ${quickView ? "" : "d-none"}`}>
              <FaEye />
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div className="card-body">
        <h5 className="card-title text-truncate">{product?.productName}</h5>
        <p className="card-text text-muted d-none">{product?.description}</p>
        <div className={`d-flex justify-content-between align-items-center ${price?"":"d-none"}`}>
          <span className={`price fw-bold d-flex align-items-center gap-1`}>            
          From<FaRupeeSign/> {product?.min_mrp}</span>
          {/* <button className="btn btn-primary btn-sm d-none">Add to Cart</button> */}
        </div>
      </div>
    </div>
    </>
    
  );
};

export default CategoryCard;
